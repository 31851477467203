import React from "react"

import Layout from "../components/layout"
import Heading1 from "../components/Heading1"
import Heading2 from "../components/Heading2"
import SEO from "../components/seo"
import styles from "./works.module.scss"

const WorksPage = () => (
  <Layout>
    <SEO title="インディーズデベロッパー 大波 誠(おおなみ まこと)の作品/ポートフォリオ一覧" />
    
    <Heading1>作品/ポートフォリオ</Heading1>
    <div className={styles.block}>
      <Heading2 text="Mooback(ムーバック)"></Heading2>
      <div className={styles.block__wrapper}>
        <div className={styles.block__description}>
          今そのときの気分を簡単に記録できる日記アプリを作りました。
          <br></br>
          記録はグラフ形式で後から振り返ることができます。
          <br></br>
          そこから自分のメンタルについて、特徴や最近の傾向を把握することができます。
        </div>
        <div className={styles.block__description}>
          <div className={styles.block__app_banner}>
            <a href="https://apps.apple.com/jp/app/id1492857135">
              <img src="/images/appstore_banner.png" alt="AppStoreのリンクはこちらです。ぜひダウンロードして使ってみてください"></img>
            </a>
          </div>
        </div>
        <div className={styles.block__description}>
          <img className={styles.block__image} src="/images/page0_5.5.png" alt="これがMoobackです!!"></img>
          <img className={styles.block__image} src="/images/page1_5.5.png" alt="タップをして今の気分を記録します"></img>
          <img className={styles.block__image} src="/images/page2_5.5.png" alt="気分の記録がグラフになります"></img>
          <img className={styles.block__image} src="/images/page3_5.5.png" alt="記録が続けやすくなる便利な機能"></img>
        </div>
        <div className={styles.block__description}>
          記録は簡単にできるけど分析はしっかりとフィードバックしてくれることを目指しています。<br></br>日記までいくと三日坊主になってしまって続かない。だけど何かしらそのときに自分が感じたことや考えたことを記録しておきたい方におすすめできるアプリにしていきたいです。
        </div>
        <div className={styles.block__description}>
          現在はiOS版のみご利用いただけます。ぜひお気軽にお試しいただけると幸いです。ご意見やご感想をお待ちしてます！
        </div>
        <div className={styles.block__description}>
          <div className={styles.block__app_banner}>
            <a href="https://apps.apple.com/jp/app/id1492857135">
              <img src="/images/appstore_banner.png" alt="AppStoreのリンクはこちらです。ぜひダウンロードして使ってみてください"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default WorksPage
